.print-invoice-container {
    flex: 1;
    margin: auto;
    padding: 3rem;
    display: flex;
    justify-content: center;
    min-height: 60rem;

    .print-invoice-wrapper {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        padding: 1rem;
        min-width: 45rem;
        border: groove;

        .info-wrapper {
            flex-direction: column;
            display: flex;

            .invoice-tower {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .tower-address {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            .issue-due-wrapper {
                display: flex;
                flex-direction: row;
                margin-top: 0.4rem;

                .issue {
                    display: flex;
                    flex-direction: column;
                }

                .due {
                    display: flex;
                    flex-direction: column;
                    margin-left: 2rem;
                }
            }

            .invoice-for-wrapper {
                margin: 1rem 0 0.2rem 0;
            }

            .customer-address-wrapper {
                display: flex;
                flex-direction: column;
            }
        }

        .table-wrapper {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin-top: 2rem;

            .header-wrapper {
                display: flex;
                flex-direction: row;
                border-top: groove;
                margin-bottom: 0.5rem;

                .header-item {
                    flex: 1;
                    font-size: 15px;
                    padding: 0.4rem;
                    background-color: #eeeeee;
                    font-weight: bold;
                }
            }

            .row-wrapper {
                display: flex;
                flex-direction: row;

                .item {
                    flex: 1;
                    font-size: 14px;
                    padding: 0 0.4rem;
                }

                .other-item {
                    flex: 1;
                    font-size: 14px;
                    padding: 0 0.4rem;
                }
            }
        }

        .liwe-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
}