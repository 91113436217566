@import '../../colors.scss';

.dot {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    display: inline-block;
}

.green {
    background-color: $primary-color;
}

.yellow {
    background-color: $warning-color;
}

.red {
    background-color: $error-color;
}

.blue {
    background-color: $aqua;
}

.orange {
    background-color: $orange;
}

.pink {
    background-color: $pink;
}

.purple {
    background-color: $purple;
}