.user-management-section {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
}

.user-card-wrapper {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
}

.user-card {
  background: #efefef;
  padding: 15px;
  margin: 15px;
  border-radius: 3px;
  border-radius: 10px;

  .user-card-title {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 0.3rem;
  }

  .user-card-description {
    font-size: 13px;
    color: gray;
    margin-bottom: 0.8rem;
  }

  .single-user-bar {
    background: white;
    border-radius: 5px;
    margin-bottom: 0.8rem;
    padding: 0.4rem;
    display: flex;
    flex-direction: row;
  }

  .user_avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    max-width: 100%;
    vertical-align: middle;
    align-self: center;
  }

  .user_details {
    display: flex;
    flex-direction: column;
    margin-left: 0.6rem;
    justify-content: space-evenly;

    .user_name {
      font-size: 0.8rem;
      font-weight: bold;
    }

    .user_email {
      color: #40404d;
      font-size: 0.7rem;
    }
  }
}

.table td {
  vertical-align: middle;
}
