.wrapper-bottom-set-mtp {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    .other-image-wrap-mtp {
        border-radius: 8px;
        border-color: "black";

        .remove-button-mtp {
            background-color: transparent;
            color: white;
            top: 7rem;
            right: 45.9rem;
            border-radius: 20px;
            background: #d44242;
            width: 20px;
            height: 20px;
            border: none;
            font-size: 10px;
        }
    }

    .other-image-upload-icon-mtp {
        width: 75px;
        height: 70px;
        background: #efefef;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        border-radius: 8px;
        color: #8bcb89;
        font-size: 18px;
    }

    img {
        width: 75px;
        height: 70px;
        object-fit: cover;
        border-radius: 10px;
        margin-top: 10px;
    }
}