.payroll-info-drawer {
  .name {
    text-align: center;
  }

  .email {
    text-align: center;
    color: #40404d;
    font-size: 0.75rem;
    font-weight: bold;
  }

  .contact-number,
  .last-seen {
    text-align: center;
    color: #40404d;
    font-size: 0.75rem;
  }

  .amount {
    font-size: 27px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
    color: #8acb87;
  }

  .drawer-sub-title {
    font-weight: bold;
  }

  .drawer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .drawer-content-item {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .drawer-user-avatar {
    display: flex;
    align-items: center;
    justify-content: stretch;
  }

  .event-history-item {
    font-weight: 500;
    font-size: 0.8rem;
  }

  .green-text {
    color: #62c35d;
  }

  .red-text {
    color: #ff5440;
  }
}
