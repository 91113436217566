@import "../../colors.scss";

.body {
    padding: 2rem;
}

#feedback-table tbody tr td:nth-child(1) {
    font-weight: bold;
}
#feedback-table tr th:nth-child(4),
#feedback-table tbody tr td:nth-child(4) {
    text-align: center;
}

#feedback-table tbody tr td:nth-child(7),
#feedback-table tr th:nth-child(7) {
    text-align: left;
}

.icon {
    height: 40px;
    width: 40px;
    border-radius: 11px;
}

.gear {
    background-color: $bg-grey;
    margin-right: 1rem;
}

.add {
    background-color: $primary-color;
}
