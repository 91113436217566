.filter-area {
    display: flex;
    flex-direction: row;
}

.filter-area-item {
    align-self: center;
    margin-right: 2%;
}

.filter-text {
    margin-top: 1%;
}

.table {
    margin-top: 1rem;
}

.table-header-text {
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

.download-table-xls-button {
    width: 100%;
    border: 0;
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    font-weight: 500;
    background-color: #4BBB46;
}

.available {
    background-color: #4BBB46;
}

.occupied {
    background-color: rgb(219, 47, 47);
}

.temp-occupied {
    background-color: rgb(235, 143, 23);
}

.colour-coder {
    display: flex;
    justify-content: space-evenly;
    margin: 1rem;
}

.circular {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin-right: 0.9rem;
}

.text-aligner {
    display: flex;
    flex-direction: row;
}

.cell-border {
    border: black !important;
    border-style: solid !important;
}