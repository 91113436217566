.settings {
  &.payments {
    .white-box {
      padding: 30px 20px;
      border-radius: 5px;
      box-shadow: 0 0 2px #c3c3c3;
      max-width: 910px;
    }

    .white-box-inner {
      padding: 10px;
      border-radius: 1px;
      margin-left: 50px;
      // box-shadow: 0 0 2px #c3c3c3;
    }
  }

  .icon {
    background: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8acb87;
    border-radius: 50%;
  }

  label,
  .input-lbl {
    min-width: 220px;
    background-color: #ececec;
    padding: 6px;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    font-weight: normal;
    margin-bottom: 0;
    .large{
      min-width: 220px;
    }
  }

  .btn-decline {
    background-color: #40404d;
  }

  .title {
    font-weight: 500;
    size: 18px;
  }
  .description {
    opacity: 0.8;
  }

  .sub-title {
    size: 14px;
    color: #40404d;
    font-weight: 500;
  }
}

.gren-dot {
  color: #28a745;
}

.white-select-input {
  background: white !important;
}

.green-select-input {
  background: #4bbb46 !important;
  color: #ececec;
}

.main-image-wrap {
  width: 420px;
  height: 220px;
  position: relative;
  background-color: lightgray;
  border-radius: 5px;
  overflow: hidden;
}

.upload-icon {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 95px;
  left: 185px;
  .fas {
    color: #8bcb89;
  }
}
