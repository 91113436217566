.section-1,
.section-2,
.section-3,
.section-4,
.section-5,
.section-6,
.section-7,
.section-8,
.section-9,
.section-10,
.section-11,
.section-12,
.section-13 {
  margin-top: 10px;
}

.section {
  margin: 0 40px 0 40px;
  
  .section-heading {
    font-size: 25px;
    font-weight: 500;
  }
}

.heading {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 55px 0 30px 0;
}

p {
  color: black;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
}

.icon {
  margin: 30px 0 0 30px;
}

.company-icon{
  width: 150px;
}