.notices-wrapper {
    .notice {
        background-color: white;
        box-shadow: 0 0 6px 0px #e6e6e6;
        padding: 15px;
        margin: 10px;
        border-radius: 3px;
    }

    .status-btn {
        padding: 5px 20px;
        border-radius: 30px;
        background-color: #e0f1df;
        color: #8aca88;
        font-size: 13px;
        display: inline-block;
        margin-top: 15px;
        font-weight: bold;

        &.draft {
            background-color: #febe4da1;
            color: white;
        }
    }
}

.wrapper-bottom-set {
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 9px;
    flex-direction: row;
    flex-wrap: wrap;

    .other-image-wrap {
        position: relative;
        border-radius: 8px;
        border-color: "black";

        .remove-button {
            position: absolute;
            background-color: transparent;
            color: white;
            top: 13px;
            right: 15px;
            border-radius: 20px;
            background: #d44242;
            width: 20px;
            height: 20px;
            border: none;
            font-size: 10px;
        }
    }

    .other-image-upload-icon {
        width: 75px;
        height: 70px;
        background: #efefef;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        border-radius: 8px;
        color: #8bcb89;
        font-size: 18px;
    }

    img {
        width: 75px;
        height: 70px;
        object-fit: cover;
        border-radius: 10px;
        margin-top: 10px;
    }
}