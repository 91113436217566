@import "../../colors.scss";

.body {
  padding: 2rem;
}

#support-tickets-table tr th:nth-child(7),
#support-tickets-table tbody tr td:nth-child(7) {
  text-align: right;
}

.image-upload-wrap {
  width: 370px;
  // height: 220px;
  max-height: 300px;
  margin-bottom: 20px;
  margin-top: 10px;
  position: relative;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: auto;

  .upload-icon {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 95px;
    left: 45%;
    .fas {
      color: #8bcb89;
    }
  }
}

img.img-main {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon {
  height: 40px;
  width: 40px;
  border-radius: 11px;
}

.gear {
  background-color: $bg-grey;
  margin-right: 1rem;
}

.add {
  background-color: $primary-color;
}

.support-ticket-side-drawer-header {
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 500;
  padding: 15px 0;
  min-height: 4rem;

  .icon-close {
    font-size: 20px;
    color: #ffbe46;
    float: left;
    position: absolute;
    top: 15px;
    left: 15px;
  }
}

.m-des-hd {
  font-weight: 700;
  color: #222;
  margin-bottom: 10px;
  font-size: 17px;
}

textarea.form-control {
    
    border: 1px solid rgb(75, 187, 70);
}

