.side-view {
    position: fixed;
    right: 0px;
    width: 0px;
    background: white;
    height: 99.8vh;
    border-left: 2px solid #f7f7f7;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    box-shadow: 0rem 0rem 5rem 0rem #888888;

    &.open {
        width: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}