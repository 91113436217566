@import "colors.scss";
@import "~bootstrap/scss/bootstrap";
@import "~antd/dist/antd.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  background-color: $body-bg;

  a {
    text-decoration: none;
    color: #8acb87;
    font-weight: 500;
  }

  .react-time-picker__wrapper {
    border: none;
  }

  .Toastify__toast {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding-left: 20px;
    border-radius: 3px;
  }

  .Toastify__toast--success {
    background-color: #8acb87;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #4bbb46;
  }

  .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #4bbb46;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #4bbb46;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #4bbb46;
  }
}

.body {
  padding: 0rem 2rem;
  position: relative;
}

.Toastify__toast--error {
  // background: #f40105 !important;
  // opacity: 0.8;
}

table tr:hover {
  background-color: rgb(241, 241, 241);
}

button.ant-btn.ant-btn-primary.ant-btn-sm {
  background: #4bbb46;
  border: none;
  width: 100px;
  font-weight: bold;
  border-radius: 5px;
  height: 40px;
  display: block;
  text-transform: uppercase;
  margin: 0 auto;
  margin-right: 5px;
}

.ant-picker-ranges {
  .ant-picker-ok {
    margin-left: 0px;
  }

  padding: 4px 0px;
}

.cursor-pointer {
  cursor: pointer;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

.btn-primary:disabled {
  background-color: gray;
}

.dashboard-layout {
  display: grid;
  grid-template-columns: 260px 1fr;
}

.dashboard-layout-mobile {
  display: grid;
  grid-template-columns: auto 1fr;
}

.cst-btn-background {
  color: $primary-color;
}

p {
  margin-bottom: 0rem;
  color: $light-grey;
}

h1 {
  font-weight: bold;
  font-size: 1.5rem;
  color: $dark-grey;
}

h4 {
  font-weight: bold;
  font-size: 1rem;
  color: $dark-grey;
}

table td,
table th {
  color: $dark-grey;
}

.font-yellow {
  color: $warning-color;
}

.font-blue {
  color: $light-blue;
  font-weight: bold;
}

.font-dark-grey {
  color: $dark-grey;
  font-weight: bold;
}

.font-green {
  color: $green;
}

.font-red {
  color: $error-color;
}

.bg-green {
  background-color: $primary-color;
}

.w-20 {
  width: 20% !important;
}

.nav-link {
  padding: 0px;
}

a {
  color: $dark-grey;
}

a:hover {
  color: $dark-grey;
}

.table {
  margin-top: 2rem;
}

.table td {
  font-size: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table th {
  font-size: 0.9rem;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.btn {
  border-radius: 5rem;
  width: 6rem;
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-primary:hover {
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-warning {
  background-color: $warning-color;
  border-color: $warning-color;
  color: white;
}

.btn-warning:hover {
  background-color: $warning-color;
  border-color: $warning-color;
  color: white;
}

.btn-danger {
  background-color: $error-color;
  border-color: $error-color;
  color: white;
}

.btn-danger:hover {
  background-color: $error-color;
  border-color: $error-color;
  color: white;
}

.red-button {
  width: 100%;
  background-color: white;
  border: 0;
  padding: 10px 20px;
  font-size: 14px;
  color: $error-color;
  margin: 6px 0 20px 0;
  border-radius: 4px;
}

.green-button {
  background-color: $primary-color;
}

.btn {
  width: 100%;
  border: 0;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  // margin: 6px 0 20px 0;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  font-weight: 500;

  &:hover {
    color: #fff;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
  }
}

.search-field {
  border-radius: 4px;

}

.app-icons {
  font-size: 22px;
}