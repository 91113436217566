@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@import "./App.scss";

body {
    font-family: "Inter", sans-serif !important;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb {
    background-color: #8f9bb3;
    border-radius: 0px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
}

body::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
}

body::-webkit-scrollbar-thumb {
    background-color: #8acb87a8;
    border-radius: 0px;
}

.chartjs-render-monitor {
    max-width: 100%;
}

.header-wrap .name-image img {
    border: 2px solid #ffbe46;
}

// .sidebar-wrapper .logo {
//     padding: 20px 30px 30px;
//     position: relative;

//     &:after {
//         content: "";
//         position: absolute;
//         width: calc((100% - 60px));
//         height: 1px;
//         background-color: #fff;
//         bottom: 0;
//         left: 30px;
//     }

//     img {
//         margin: 0;
//     }
// }

.dashboard-screen .stats-wrapper div:nth-child(2) {
    .box-section {
        margin: 0;
        gap: 10px;
    }
}

.header-wrap .name-image .name-wrap {
    padding-right: 2rem;
}

.dashboard-screen .box {
    width: calc(25% - 8px);
    padding-top: calc(25% - 8px);

    .content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.dashboard-screen .user .user_details .user_name {
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 8px;
}

.dashboard-screen .user .user_details .user_role {
    font-size: 12px;
}

.dashboard-screen .user_avatar {
    align-self: flex-start;
    transform: translateY(5px);
}

.body {
    >h2 {
        font-size: 35px;
        font-weight: 700;
    }
}

.navbar-wrap .tab-section a {
    font-weight: 700;
    font-size: 26px;
    margin-right: 50px;
}

.header-wrap .profile-wrap .fas {
    margin-left: 0;
    font-size: 22px;
}

.header-wrap .profile-wrap {
    .noti-btn {
        position: relative;

        .noti-dot {
            position: absolute;
            width: 23px;
            height: 23px;
            background-color: #48c741;
            border-radius: 50%;
            display: grid;
            place-items: center;
            color: #fff;
            font-weight: 700;
            font-size: 10px;
            text-align: center;
            border: 2px solid #fff;
            bottom: 0;
            right: -3px;
        }

        &.noti-bell {
            .noti-dot {
                bottom: -6px;
                right: -3px;
                background-color: #ff5440;
            }
        }
    }
}

.side-view {
    right: -420px;
    width: 400px;
}

.side-view.open {
    width: 400px;
    right: 0;
}

.side-drawer-header+div {
    .btn {
        font-size: 12.8px;
    }
}

.add-facility-wrapper {
    .afw-top {
        padding: 0;
        margin-bottom: 2rem;

        .title {
            font-size: 35px;
            font-weight: 700;
            margin-left: 0;
        }
    }

    .lft-col {
        flex: 0 0 560px;
        max-width: 560px;
        padding: 0;

        .wrapper-images-set {
            width: 100%;
            margin-top: 0px;

            .main-image-wrap {
                width: 100%;
                height: 300px;
                display: grid;
                place-items: center;

                .upload-icon {
                    top: auto;
                    left: auto;
                }
            }
        }

        .wrapper-bottom-set .other-image-upload-icon {
            width: 100px;
            height: 100px;
        }

        .wrapper-bottom-set img {
            width: 100px;
            height: 100px;
        }

        .wrapper-bottom-set .other-image-wrap .remove-button {
            top: 5px;
            right: 2px;
        }

        .wrapper-info .wrapper-form .inp input[type="textarea"] {
            height: 180px;
        }

        .wrapper-info .wrapper-form .inp textarea {
            -webkit-appearance: none;
            appearance: none;
            width: 100%;
            border: 0;
            font-family: inherit;
            padding: 25px 12px 0 12px;
            height: 55px;
            transition: all 0.15s ease;
            border: 1px solid #4bbb46;
            border-radius: 5px;
            width: 100%;
            height: 180px;
        }

        .wrapper-info .wrapper-form .inp textarea:not(:placeholder-shown)+.label {
            color: rgba(0, 0, 0, 0.5);
            transform: translate3d(0, -7px, 0) scale(0.75);
        }
    }

    .rht-col {
        flex: 0 0 calc(100% - 560px);
        max-width: calc(100% - 560px);
        padding-left: 50px;

        .wrapper-availability {
            padding-left: 35px;

            .wrapper-form .label-radio {
                margin-right: 0;
                margin-bottom: 0;
            }
        }

        .wrapper-days {
            margin-bottom: 0px;
        }

        .wrapper-duration-outer {
            padding-left: 30px;
        }

        .wrapper-duration {
            &:first-of-type {
                margin-top: 10px;
            }

            .title {
                font-size: 15px;
                margin-bottom: 4px;
                font-weight: 500;
                color: #40404d;
            }

            &.sh-ex {
                .cursor-pointer {
                    margin-bottom: 0;
                }
            }
        }

        .wrapper-associated-charges {
            padding-left: 50px;
        }
    }
}

.payment-page {
    .invoices-table {
        th:last-child {
            text-align: center;
        }
    }

    .invoices-table .wrapper-payments-table {
        td {
            vertical-align: middle;

            &.td-status {
                display: table-cell;
            }
        }
    }
}

.payment-info-drawer {
    .ii-inner {
        .label-heading {
            margin-bottom: 10px;
            font-weight: 700 !important;
            font-size: 18px !important;
        }

        .ii-top {
            display: flex;
            flex-direction: row;
            margin-bottom: 5px;

            .ii-lft {
                flex: 1 1;
                font-size: 14px;
                font-weight: 600;
                color: #000;
                opacity: 0.7;
                margin-left: 0.3rem;
            }

            .ii-rht {
                flex: 1;
                font-size: 15px;
                font-weight: 700;
                color: #000;
            }
        }
    }
}

.payment-page .payment-info-drawer .make-payment {
    .payee-lbl {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .lbl {
        font-size: 14px;
        font-weight: 700;
        color: #000;
        margin-bottom: 5px;
    }

    .form-control {
        border-radius: 8px;
        background: #fff;
        border: 2px solid #ebebec;
        font-size: 14px;
        height: 40px;
    }
}

.notices-wrapper {
    .cursor-pointer {
        margin-bottom: 30px;
    }

    .notice {
        min-height: 100%;
        margin: 0;
    }
}

.facilities-items-wrap .box .background-black {
    background-color: #00000085;
}

.box span {
    font-size: 20px;
    font-weight: 700;
    padding: 0 15px;
    text-align: center;
    line-height: 1.2;
}

.facilities-items-wrap .box .inactive {
    color: #262626;
    text-transform: uppercase;
    font-weight: 700;
}

.modal-content {
    border: 0;
    border-radius: 3px;
    outline: 0;

    .modal-title {
        font-weight: 700;
        font-size: 22px;
        margin-right: 50px;
        color: #4bbb46;

        .form-control {
            border: 1px solid #4bbb46;
            font-size: 14px;
            line-height: 1.5;
            margin-bottom: 5px;
        }
    }

    .m-des-hd {
        font-weight: 700;
        color: #222;
        margin-bottom: 10px;
        font-size: 17px;
    }

    .m-des {
        font-weight: 400;
        color: #222;
        margin-bottom: 0px;
        font-size: 14px;
        line-height: 1.5;
    }

    .modal-body {
        textarea.form-control {
            border: 1px solid #4bbb46;
            font-size: 14px;
            line-height: 1.5;
        }
    }
}

.create-invoice-wrap {
    h4 {
        margin-top: 20px;
    }

    .form-control {
        border: 1px solid #4bbb46;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 5px;
    }
}

.settings.payments .white-box-inner {
    padding: 10px 5px;
}

.settings.payments {
    font-size: 14px;
    line-height: 1.5;

    input.form-control {
        border-radius: 8px;
        background: #fff;
        border: 2px solid #ebebec;
        height: 35px;
    }

    .ant-picker {
        border-radius: 8px;
        background: #fff;
        border: 2px solid #ebebec;
    }

    .MuiSwitch-root {
        margin-left: 20px;
    }
}



@media (min-width: 1200px) and (max-width: 1680px) {
    // .sidebar-wrapper .logo {
    //     padding: 20px 22px 25px;
    // }

    // .sidebar-wrapper .logo:after {
    //     width: calc((100% - 44px));
    //     left: 22px;
    // }

    // .dashboard-layout {
    //     display: grid;
    //     grid-template-columns: 220px calc(100% - 220px);
    // }

    // .sidebar-wrapper .sidenav {
    //     width: 220px;
    // }

    // .sidebar-wrapper .logo {
    //     padding: 6px 8px;
    //     display: grid;
    //     // place-items: center;
    // }
    // .sidebar-wrapper .nav-link .nav-item {
    //     height: 50px;
    //     margin-left: 20px;

    //     img {
    //         width: 18px;
    //     }

    //     i {
    //         font-size: 18px !important;
    //     }
    // }

    // .sidebar-wrapper .sidenav .text {
    //     padding-left: 15px;
    //     font-size: 14px;
    // }

    .dashboard-screen .dashboard-grid {
        margin-top: 30px;
        grid-template-columns: minmax(0, 65%) minmax(0, 35%);
        gap: 30px 25px;
    }

    .dashboard-screen .value-card-amount {
        font-size: 22px;
    }

    .body {
        padding: 0 25px;
    }

    .dashboard-screen .condo-section {
        margin-top: 0.25rem;
    }

    .dashboard-screen .condo-icons {
        height: 50px;
        width: 50px;
        margin-right: 10px;

        .largeIcon {
            width: 25px;
            height: 25px;
        }
    }

    .dashboard-screen .dashboard-grid .payment-status-wrap {
        grid-template-columns: repeat(3, minmax(0, 33.33333333%));
        gap: 15px;
    }

    .dashboard-screen .condo-stats-heading {
        font-size: 14px;
    }

    .dashboard-screen .condo-stats-amount {
        font-size: 28px;
        line-height: 1.35;
    }

    .dashboard-screen .value-card {
        padding: 20px 15px;
    }

    .dashboard-screen .title-wrap {
        margin-bottom: 10px;
    }

    //.header-wrap .name-image .name-wrap {
    //    padding-right: 25px;
    //}
    //
    //.header-wrap .name-image .name-wrap .toggle-menu {
    //    top: 50px;
    //    left: auto;
    //    right: 25px;
    //    text-align: center;
    //}

    .dashboard-screen .stats-wrapper div:nth-child(2) {
        .box-section {
            //  margin: 0 -5px;
        }
    }

    .dashboard-screen .box {
        width: calc(33.33333333% - 7px);
        padding-top: calc(33.33333333% - 7px);
    }

    .dashboard-screen .user .user_details .user_name {
        font-size: 15px;
    }

    .dashboard-screen .date .date_content .amount {
        font-size: 15px;
    }

    .dashboard-screen .value-card-heading {
        font-size: 15px;
        font-weight: 500;
    }

    .dashboard-screen .user_avatar {
        width: 45px;
        height: 45px;
    }

    .dashboard-screen .booking-counter {
        font-size: 1.85rem !important;
        line-height: 1.35;
    }

    .body>h2 {
        font-size: 28px;
    }

    .navbar-wrap .tab-section a {
        font-size: 18px;
        margin-right: 40px;
    }

    .add-facility-wrapper .lft-col {
        flex: 0 0 400px;
        max-width: 400px;
    }

    .add-facility-wrapper .rht-col {
        flex-basis: calc(100% - 400px);
        max-width: calc(100% - 400px);
        padding-left: 30px;
    }

    .add-facility-wrapper .rht-col .wrapper-duration-outer {
        padding-left: 15px;
    }

    .add-facility-wrapper .wrapper-info {
        width: 100%;
        margin-top: 30px;
    }

    .add-facility-wrapper .lft-col .wrapper-bottom-set .other-image-upload-icon {
        width: 73px;
        height: 73px;
    }

    .add-facility-wrapper .lft-col .wrapper-bottom-set img {
        width: 73px;
        height: 73px;
    }

    .add-facility-wrapper .lft-col .wrapper-bottom-set .other-image-wrap .remove-button {
        right: -3px;
    }

    .add-facility-wrapper .rht-col .wrapper-availability {
        padding-left: 0;
        transform: translateX(-5px);
    }

    .add-facility-wrapper .time-picker {
        width: 175px;
        align-items: flex-start;
    }

    .add-facility-wrapper .lft-col .wrapper-images-set .main-image-wrap {
        height: 250px;
    }

    .add-facility-wrapper .wrapper-bottom-set {
        margin-top: 0px;
    }

    .add-facility-wrapper .wrapper-availability .wrapper-form .wrapper-days .label-checkbox {
        font-size: 14px;
    }

    .add-facility-wrapper .lft-col .wrapper-info .wrapper-form .inp textarea {
        height: 150px;
        font-size: 14px;
    }

    .add-facility-wrapper .wrapper-info .wrapper-form .inp input {
        font-size: 14px;
    }

    .add-facility-wrapper .wrapper-title-associated-charges {
        margin-top: 10px;
    }

    .add-facility-wrapper .afw-top {
        margin-bottom: 25px;
    }

    .side-drawer-header {
        min-height: 12rem;

        .icon-cir {
            width: 5rem !important;
            height: 5rem !important;

            .icon-cir-img {
                width: 3rem !important;
                height: 3rem !important;
            }
        }
    }

    .app-calendar .week-row .title {
        font-size: 15px;
        min-width: 80px;
    }

    .booking-wrap .booking-box {
        padding: 5px;
        border-radius: 5px;
        margin: 5px;
    }

    .app-calendar .booking-box .amenity-name {
        padding-bottom: 7px;
        font-size: 12.8px;
    }

    .app-calendar .booking-box .booking-time,
    .app-calendar .booking-box .booked-by,
    .app-calendar .booking-box .amenity-type {
        font-size: 12px;
    }

    .app-calendar .booking-box .booking-time .far,
    .app-calendar .booking-box .booking-time .fas,
    .app-calendar .booking-box .booked-by .far,
    .app-calendar .booking-box .booked-by .fas,
    .app-calendar .booking-box .amenity-type .far,
    .app-calendar .booking-box .amenity-type .fas {
        width: 12px;
    }
}

@media (min-width: 1200px) and (max-width: 1300px) {
    .dashboard-screen .dashboard-grid {
        grid-template-columns: minmax(0, 62%) minmax(0, 38%);
        gap: 30px 20px;
    }

    .dashboard-screen .value-card {
        padding: 15px 10px;
    }

    .dashboard-screen .value-card {
        padding: 15px 10px;
    }

    .dashboard-screen .value-card-heading {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .dashboard-screen .value-card-amount {
        font-size: 21px;
    }

    .add-facility-wrapper .rht-col .wrapper-associated-charges {
        padding-left: 15px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {

    .facility-navbar-wrap .navbar-wrap .tab-section a {
        font-size: 16px;
        margin-right: 25px;
    }

    // .sidebar-wrapper .logo {
    //     padding: 20px 22px 25px;
    // }

    // .sidebar-wrapper .logo:after {
    //     width: calc((100% - 44px));
    //     left: 22px;
    // }

    .app-calendar {
        overflow-x: auto;
    }

    .app-calendar .week-row .title {
        font-size: 15px;
        min-width: 80px;
    }

    .booking-wrap .booking-box {
        padding: 5px;
        border-radius: 5px;
        margin: 5px;
    }

    .app-calendar .booking-box .amenity-name {
        padding-bottom: 7px;
        font-size: 10px;
    }

    .app-calendar .booking-box .booking-time,
    .app-calendar .booking-box .booked-by,
    .app-calendar .booking-box .amenity-type {
        font-size: 10px;
    }

    .app-calendar .booking-box .booking-time .far,
    .app-calendar .booking-box .booking-time .fas,
    .app-calendar .booking-box .booked-by .far,
    .app-calendar .booking-box .booked-by .fas,
    .app-calendar .booking-box .amenity-type .far,
    .app-calendar .booking-box .amenity-type .fas {
        width: 8px;
    }

    .table td {
        white-space: pre-wrap;
    }

    .side-drawer-header {
        min-height: 12rem;

        .icon-cir {
            width: 5rem !important;
            height: 5rem !important;

            .icon-cir-img {
                width: 3rem !important;
                height: 3rem !important;
            }
        }
    }

    // .dashboard-layout {
    //     display: grid;
    //     grid-template-columns: 220px calc(100% - 220px);
    // }

    // .sidebar-wrapper .sidenav {
    //     width: 220px;
    // }

    // .sidebar-wrapper .logo {
    //     padding: 6px 8px;
    //     display: grid;
    //     // place-items: center;
    // }
    // .sidebar-wrapper .nav-link .nav-item {
    //     height: 50px;
    //     margin-left: 20px;

    //     img {
    //         width: 18px;
    //     }

    //     i {
    //         font-size: 18px !important;
    //     }
    // }

    // .sidebar-wrapper .sidenav .text {
    //     padding-left: 15px;
    //     font-size: 14px;
    // }

    .dashboard-screen .dashboard-grid {
        margin-top: 30px;
        gap: 30px 25px;
    }

    .dashboard-screen .value-card-amount {
        font-size: 18px;
    }

    .body {
        padding: 0 25px;
    }

    .dashboard-screen .condo-section {
        margin-top: 0.25rem;
    }

    .dashboard-screen .condo-icons {
        height: 50px;
        width: 50px;
        margin-right: 10px;

        .largeIcon {
            width: 25px;
            height: 25px;
        }
    }

    .dashboard-screen .dashboard-grid .payment-status-wrap {
        grid-template-columns: repeat(3, minmax(0, 33.33333333%));
        gap: 15px;
    }

    .dashboard-screen .condo-stats-heading {
        font-size: 14px;
    }

    .dashboard-screen .condo-stats-amount {
        font-size: 28px;
        line-height: 1.35;
    }

    .dashboard-screen .value-card {
        padding: 20px 15px;
    }

    .dashboard-screen .title-wrap {
        margin-bottom: 10px;
    }

    .header-wrap .name-image .name-wrap {
        padding-right: 25px;
    }

    .dashboard-screen .stats-wrapper div:nth-child(2) {
        .box-section {
            //  margin: 0 -5px;
        }
    }

    // .dashboard-screen .box {
    //     width: calc(33.33333333% - 7px);
    //     padding-top: calc(33.33333333% - 7px);
    // }

    .dashboard-screen .user .user_details .user_name {
        font-size: 15px;
    }

    .dashboard-screen .date .date_content .amount {
        font-size: 15px;
    }

    .dashboard-screen .value-card-heading {
        font-size: 12.8px;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .dashboard-screen .user_avatar {
        width: 45px;
        height: 45px;
    }

    .dashboard-screen .booking-counter {
        font-size: 1.85rem !important;
        line-height: 1.35;
    }

    .table td:last-child {
        white-space: pre-wrap;
    }

    .body>h2 {
        font-size: 28px;
    }

    .navbar-wrap .tab-section a {
        font-size: 20px;
        margin-right: 40px;
    }

    .add-facility-wrapper .lft-col {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .add-facility-wrapper .rht-col {
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 0px;
        margin-bottom: 40px;
    }

    .add-facility-wrapper .rht-col .wrapper-duration-outer {
        padding-left: 15px;
    }

    .add-facility-wrapper .wrapper-info {
        width: 100%;
        margin-top: 30px;
    }

    .add-facility-wrapper .lft-col .wrapper-bottom-set .other-image-upload-icon {
        width: 140px;
        height: 140px;
    }

    .add-facility-wrapper .lft-col .wrapper-bottom-set img {
        width: 140px;
        height: 140px;
    }

    .add-facility-wrapper .lft-col .wrapper-bottom-set .other-image-wrap .remove-button {
        right: -3px;
    }

    .add-facility-wrapper .rht-col .wrapper-availability {
        padding-left: 0;
        transform: translateX(-5px);
    }

    .add-facility-wrapper .time-picker {
        width: 175px;
        align-items: flex-start;
    }

    .add-facility-wrapper .lft-col .wrapper-images-set .main-image-wrap {
        height: 300px;
    }

    .add-facility-wrapper .wrapper-bottom-set {
        margin-top: 0px;
    }

    .add-facility-wrapper .wrapper-availability .wrapper-form .wrapper-days .label-checkbox {
        font-size: 14px;
    }

    .add-facility-wrapper .lft-col .wrapper-info .wrapper-form .inp textarea {
        height: 150px;
        font-size: 14px;
    }

    .add-facility-wrapper .wrapper-info .wrapper-form .inp input {
        font-size: 14px;
    }

    .add-facility-wrapper .wrapper-title-associated-charges {
        margin-top: 10px;
    }

    .add-facility-wrapper .afw-top {
        margin-bottom: 25px;
    }

    .add-facility-wrapper .rht-col .wrapper-associated-charges {
        padding-left: 10px;
    }
}