.app-loading {
    // position: fixed;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // left: 0px;
    // right: 0;
    // top: 0;
    // bottom: 0;
    // z-index: 10;
    // background: #00000038;
    position: fixed;
    display: grid;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background: #00000038;
    width: 100%;
    height: 100%;
}
