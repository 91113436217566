@import "../../colors.scss";

.users-table {
    border: none;
    border-collapse: collapse;

    tr,
    td {
        border: none;
    }

    .user-type {
        padding-right: 5px;
        border-left: 1px solid #d4d4d4;
        display: block;
        float: left;
        padding-left: 5px;
    }
}

.users-table tbody tr td:nth-child(7),
.users-table tr th:nth-child(7) {
    text-align: center;
}

.icon {
    height: 40px;
    width: 40px;
    border-radius: 11px;
}

.gear {
    background-color: $bg-grey;
    margin-right: 1rem;
}

.add {
    background-color: $primary-color;
}

.clickable {
    cursor: pointer;
}

.user-drawer {
    .profile-pic.small {
        width: 6.5rem;
        height: 6.5rem;
    }

    .user-name {
        font-size: 0.8rem;
        font-weight: bold;
    }

    .apartment-number {
        margin-top: 0.2rem;
        font-size: 0.7rem;
        font-weight: 500;
    }

    .rented-since {
        font-size: 0.6rem;
        font-weight: 500;
    }

    .name-section {
        width: 100%;
        text-align: center;
    }

    .btn-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .header-icon-spacing {
        margin-top: 2.8rem;
    }

    .ellipsis-icon-spacing {
        margin-top: 3.1rem;
    }

    .floor-plan {
        font-size: 0.7rem;
        font-weight: bold;
        margin-bottom: 0.45rem;
    }

    .floor-detail {
        font-size: 0.6rem;
        margin-bottom: 0.1rem;
    }

    .heading {
        font-size: 0.9rem;
        font-weight: bold;
    }

    .green-link {
        font-size: 0.8rem;
        font-weight: 500;
        color: $primary-color;
    }

    .margin-bottom {
        margin-bottom: 1rem;
    }

    .apartment-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        margin-bottom: 15px;

        .building-img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 15px;
        }
    }

    .document {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .fas,
        .far {
            background-color: #2082fd;
            padding: 10px;
            border-radius: 5px;
            color: white;
            margin-right: 15px;
        }

        .file-name {
            font-weight: 500;

            .registered-date {
                font-size: 12px;
                color: gray;
                font-weight: 500;
            }
        }
    }
}



.filter-wrapper.filter-area-user .filter-item{
    margin-right: 0;
    .label {
        min-width: 80px;
    }
}
.filter-area-user {
    top: 140px;
    right: 47px;
}
@media (min-width: 768px) and (max-width: 1680px) {
    .filter-area-user {
        top: 127px;
        right: 40px;
    }
}