.app-calendar {
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 338px);
  margin-top: 30px;

  .week-row {
    border-right: 2px solid #f1f1f1;
    width: 100%;




    .title {
      text-align: center;
      font-weight: bold;
      padding-bottom: 10px;
      font-size: 20px;
      color: #40404D;
      border-bottom: 2px solid #f1f1f1;

      &.same-day {
        color: #4ebb4b;
      }
    }
  }

  .booking-box {
    .amenity-name {
      font-weight: 500;
      padding-bottom: 10px;
      font-size: 14px;
      border: none;
      text-transform: capitalize;
    }

    .booking-time,
    .booked-by,
    .amenity-type {
      font-size: 13px;

      .far,
      .fas {
        margin-right: 5px;
        width: 16px;
      }
    }

    .booked-by,
    .amenity-type {
      font-weight: 500;
      display: flex;
      align-items: baseline;
    }
  }

  .week-row:last-child {
    border-right: none;
  }

}