.admin-table-container {
    .accordion-summery {
        margin: 0px;
        display: block;
    }

    .accordion-data {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        padding: 0px 10px;

        .header_ {
            padding: 10px 0px;
            display: flex;

            .header-text {
                font-weight: 500;
                font-size: 14px;
            }
        }

        .flex-end {
            display: flex;
            justify-content: flex-end;
        }

        .medium {
            flex: 5;
        }

        .small {
            flex: 1;
        }

        .apartment-data {
            display: flex;
            padding: 5px 0px;
            font-weight: 400;

            .body-text {
                color: gray;
                font-size: 14px;
                display: flex;
                align-items: center;
            }
        }
    }
    .table {
        display: flex;
        flex-direction: column;
        padding-top: 40px;

        .large {
            flex: 1;
        }

        .medium {
            flex: 0.8;
        }

        .small {
            flex: 0.6;
        }

        .justify {
            justify-content: space-between;
        }

        .justify-end {
            justify-content: end;
        }

        .text {
            font-weight: 500;
            font-size: 14px;
        }

        @media (max-width: 1440px) {
            .text {
                font-size: 12px;
            }
        }

        .space {
            padding: 2px 10px;
        }

        .header {
            border-bottom: groove;
            border-bottom-width: 1px;
            padding: 0px 102px 13px 20px;
            display: flex;

            .header-row {
                display: flex;
                align-items: center;

                .icon {
                    margin-left: 8px;
                    cursor: pointer;
                }
            }

            .filter-icon {
                cursor: pointer;
                width: 18px;
                opacity: 0.3;
            }

            .size-box {
                width: 32px;
            }
        }

        .data {
            display: flex;
            flex-direction: column;

            .pending-padding {
                padding: 10px 0px;
            }

            .submitted-padding {
                padding: 16px 0px;
            }

            .data-row {
                display: flex;
                flex-direction: row;
                color: gray;
                // margin: 0px 40px;
                border-bottom: groove;
                border-bottom-width: 0px;

                .icon {
                    cursor: pointer;
                }

                .space {
                    padding-left: 10px;
                }
                .cell {
                    display: flex;
                    align-items: center;

                    .date {
                        border-right: groove;
                        padding-right: 8px;
                        margin-right: 8px;
                        border-right-color: gray;
                        border-right-width: 1px;
                    }

                    // .red {
                    //     background: $urgency-background-red;
                    //     color: $red;
                    // }

                    // .yellow {
                    //     background: $urgency-background-yellow;
                    //     color: $yellow;
                    // }

                    // .light-blue {
                    //     background: $urgency-background-light-blue;
                    //     color: $light-blue;
                    // }

                    // .dark-blue {
                    //     background: $urgency-background-dark-blue;
                    //     color: $dark-blue;
                    // }

                    .urgency-box {
                        display: flex;
                        width: 87px;
                        height: 35px;
                        border-radius: 8px;
                        align-items: center;
                        justify-content: center;

                        .icon {
                            width: 17px;
                            height: 17px;
                            margin-right: 4px;
                        }
                    }

                    .icon-box {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .pointer {
                cursor: pointer;
            }
        }
    }

    .accordion-data {
    }
}
