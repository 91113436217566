.header-wrap {
    display: grid;
    grid-template-columns: 1fr auto;
    // gap: 30px;
    padding-top: 10px;
    padding-left: 30px;
    padding-bottom: 10px;
    // align-items: flex-start;
    // background-color: #8bcb89;
    margin-bottom: 10px;
    width: auto;
    

    .btn {
        float: right;
    }
    .logo {
        // padding: 6px 8px 6px 16px;
        font-size: 2rem;
    

        img {
            width: 100px;
            margin: 10px 0;
            
        }
    }
    .search-wrap {
        .fas {
            color: #8bcb89;
            margin-right: 15px;

        }

        .search {
            border: none;
        }
    }
    .logowrapper{
        display: flex;
        // grid-template-columns: auto auto auto;
        align-items: center;
        // gap: 500px;
        // background-color: red;
        justify-content: space-between;

    }

    .profile-wrap {
        display: flex;
        grid-template-columns: auto auto auto;
        align-items: center;
        gap: 25px;
        // background-color: aqua;

        .fas {
            color: #575761;
            font-size: 18px;
            margin-left: 10px;
        }
    }
    
    .name-image {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            margin-right: 15px;
        }

        .name-wrap {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 50px;
            position: relative;

            .name {
                font-weight: bold;
                margin-right: 15px;
            }

            .fas {
                cursor: pointer;
            }

            .toggle-menu {
                position: absolute;
                top: 2.3rem;
                background: white;
                width: 250px;
                border: 1px solid #efefef;
                right: 5px;
                z-index: 10;
                padding: 0;
                display: none;

                &.open {
                    display: block;
                }

                .menu-item {
                    padding: 10px;
                    border-bottom: 1px solid #f5f5f5;
                    font-weight: 500;
                    color: #545454;

                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }
        }
    }
    
    .profile-img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 50%;
    }
    
}