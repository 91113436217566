@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@include desktop {
  .frame {
    display: flex;
    height: 100%;
    overflow: hidden;
  }

  .container-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 37%;
    background: {
      image: url("../../assets/images/icons/vector1.svg");
      size: cover;
      position: center top -350px;
      repeat: no-repeat;
      color: #fdf2db33;
    }
  }

  .company {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 95px 70px 0 70px;
  }

  .under-logo {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    color: #404757;
  }

  .frame1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 55px;
  }

  .frame1-text1,
  .frame2-text1 {
    font-size: 36px;
    font-weight: 700;
    color: #404757;
  }

  .frame1-text2,
  .frame2-text2 {
    font-size: 24px;
    font-weight: 400;
    color: #404757;
  }

  .building-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container-right {
    width: 63%;
  }

  .frame2 {
    padding: 30px 0 0 90px;
  }

  .frame3 {
    margin-top: 45px;
  }

  .details {
    margin-top: 55px;
  }

  .detailse-header {
    font-size: 20px;
    font-weight: 700;
    color: #404757;
  }

  .condo-field {
    margin-top: 25px;
  }

  .field-name {
    font-size: 16px;
    font-weight: 500;
    color: #404757;
  }

  %input-field {
    border: {
      style: solid;
      radius: 8px;
      color: #d0d5dd;
    }
    padding: 10px;
    gap: 8px;
  }

  .building-name {
    @extend %input-field;
    width: 50%;
  }

  .address,
  .user-name,
  .email {
    @extend %input-field;
    width: 70%;
  }

  .plan-number {
    @extend %input-field;
    width: 16%;
  }

  .number1-field {
    @extend %input-field;
    border: {
      radius: 8px 0 0 8px;
      right: none;
    }
    width: 7%;
  }

  .number2-field {
    @extend %input-field;
    border-radius: 0 8px 8px 0;
    width: 30%;
  }

  .checkbox {
    cursor: pointer;
  }

  .terms {
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 5px;

    .text1 {
      font-size: 14px;
      font-weight: 500;
    }

    .text2 {
      font-size: 14px;
      font-weight: 600;
      color: #b45309;
    }
  }

  .register {
    margin: 25px 0 10px 0;
  }

  .register-btn {
    background-color: #f59e0b;
    font-size: 14px;
    color: #1d2939;
    font-weight: 600;
    width: 30%;
  }
}

@include mobile {
  .container-left {
    display: none;
  }

  .container-right {
    width: 100%;
  }

  .frame2 {
    padding: 30px 0 0 30px;
  }

  .frame3 {
    margin-top: 45px;
  }

  .details {
    margin-top: 55px;
  }

  .frame1-text1,
  .frame2-text1 {
    font-size: 36px;
    font-weight: 700;
    color: #404757;
  }

  .frame2-text2 {
    font-size: 24px;
    font-weight: 400;
    color: #404757;
  }

  .detailse-header {
    font-size: 20px;
    font-weight: 700;
    color: #404757;
  }

  .condo-field {
    margin-top: 25px;
  }

  .field-name {
    font-size: 16px;
    font-weight: 500;
    color: #404757;
  }

  %input-field-mobile {
    border: {
      style: solid;
      radius: 8px;
      color: #d0d5dd;
    }
    padding: 10px;
    gap: 8px;
  }

  .building-name {
    @extend %input-field-mobile;
    width: 70%;
  }

  .address,
  .user-name,
  .email {
    @extend %input-field-mobile;
    width: 90%;
  }

  .plan-number {
    @extend %input-field-mobile;
    width: 40%;
  }

  .number1-field {
    @extend %input-field-mobile;
    border: {
      radius: 8px 0 0 8px;
      right: none;
    }
    width: 15%;
  }

  .number2-field {
    @extend %input-field-mobile;
    border-radius: 0 8px 8px 0;
    width: 50%;
  }

  .checkbox {
    cursor: pointer;
  }

  .terms {
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 5px;

    .text1 {
      font-size: 14px;
      font-weight: 500;
    }

    .text2 {
      font-size: 14px;
      font-weight: 600;
      color: #b45309;
    }
  }

  .register {
    margin: 25px 0 10px 0;
  }

  .register-btn {
    background-color: #f59e0b;
    font-size: 14px;
    color: #1d2939;
    font-weight: 600;
    width: 40%;
  }
}

.company-logo{
  width: 150px
}