@import "../../colors.scss";

.apartments-page {
    padding: 0.2em;

    .icon {
        height: 40px;
        width: 40px;
        border-radius: 11px;
    }

    .apartment-name {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        padding: 15px;
    }

    .gear {
        background-color: $bg-grey;
        margin-right: 1rem;
    }

    .add {
        background-color: $primary-color;
    }

    .apartments-drawer {
        .icon-options {
            float: right;
        }

        .img-profile {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 59px;
        }

        .wrapper-top-section {
            text-align: center;
            margin-bottom: 40px;
        }

        .user-list-item {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .profile-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .wrapper-profile {
            margin-top: 20px;

            .title {
                color: #40404d;
                font-weight: 600;
            }

            .sub-title {
                font-weight: 600;
                font-size: 13px;
                color: #7b7a82;
            }

            .date {
                font-size: 11px;
            }
        }

        .wrapper-apartments {
            margin-bottom: 30px;

            .title {
                font-weight: 600;
                margin-bottom: 15px;
                display: inline-block;
            }

            .add-new {
                float: right;
                font-weight: 600;
                color: #4bbb46;
            }

            .add-new:hover {
                cursor: pointer;
            }

            .profile-pic.small {
                width: 45px;
                height: 45px;
                object-fit: cover;
                border-radius: 50%;
                margin: 0;
            }

            .wrapper-name {
                margin-left: 10px;
                .title {
                    margin: 0;
                    color: #000;
                    font-size: 15px;
                }

                .sub-title {
                    font-size: 10px;
                    margin: 0;
                }
            }

            .wrapper-icons {
                .icon-delete {
                    margin-left: 20px;
                    color: #f55441;
                }
            }
        }

        .wrapper-documents {
            margin-bottom: 30px;

            .title {
                font-weight: 600;
                margin-bottom: 15px;
                display: inline-block;
            }

            .add-new {
                float: right;
                font-weight: 600;
                color: #4bbb46;
            }

            .add-new:hover {
                cursor: pointer;
            }

            .icon-document {
                background-color: #3282ff;
                color: #fff;
                padding: 15px;
                font-size: 30px;
                border-radius: 5px;
            }

            .wrapper-name {
                display: inline-block;
                position: relative;
                left: 10px;
                top: 5px;

                .title {
                    color: #000;
                    font-size: 15px;
                    margin: 0;
                }

                .sub-title {
                    font-size: 10px;
                    margin: 0;
                }
            }

            .wrapper-icons {
                float: right;
                padding-top: 15px;

                .icon-delete {
                    margin-left: 20px;
                    color: #f55441;
                }
            }
        }

        .btn-send-message {
            background-color: #4bbb45;
            color: #fff;
            border: 1px solid #4bbb45;
            font-size: 15px;
            width: 100%;
            padding: 12px;
            border-radius: 4px;
        }

        .link-disable-user {
            margin: 15px 0;
            text-align: center;
            color: #f40105;
            font-weight: 600;
            background-color: transparent;
            box-shadow: none;
        }

        .link-disable-user:hover {
            cursor: pointer;
        }
    }
}

.filter-area-apartment {
    top: 140px;
    right: 47px;
}
@media (min-width: 768px) and (max-width: 1680px) {
    .filter-area-apartment {
        top: 127px;
        right: 40px;
    }
}