table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
}

.drawer-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: calc(100% - 375px);
    background-color: #fff;
    z-index: 999; 
    width: 375px;
    overflow-x: auto;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.drawer.open {
    right: 0;
}

.drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drawer-content {
    margin-top: 20px;
}
.icon-close{
    margin-right: 20px;
}