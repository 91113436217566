@import "../../colors.scss";

.sidebar-wrapper {
    .web-version {
        position: absolute;
        bottom: 0;
        color: white;
        font-weight: 500;
        font-size: 14px;
    }

    .logo {
        padding: 6px 8px 6px 16px;
        font-size: 2rem;
        margin-right: 10px;
        margin-bottom: 10px;
        background-color: #f7cb7c;

    }

    .image-wrapper {
        margin-top: 10px;
        margin-left: 10px;
    }

    .logo-img {
        width: 90px;
        margin-left: 20px;
    }

    .logo p {
        color: white;
    }

    .sidebar-image {
        background-size: cover;
        position: relative;
        border-radius: 0.2rem;
        box-shadow: 0px 16px 40px #c6d1da29 padding-box;
        padding: 6px 8px 6px 16px;
        height: 8rem;
        width: 216px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 5px;
        overflow: hidden;

        .gradient {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(90deg,
                    #ffffff00 25%,
                    #ffffffa6 55%,
                    #ffffff 100%);
        }
    }

    .condo-name-wrapper {
        margin-left: 25px;
        margin-top: 15px;
    }

    .condo-name {
        font-size: 0.8rem;
        font-weight: bold;
        color: white;
    }

    .numbers {
        font-size: 0.7rem;
        font-weight: 500;
        color: white;
    }

    .last-accessed {
        font-size: 0.6rem;
    }

    .last-accessed span {
        font-weight: 500;
    }

    .footer {
        padding: 0px 8px 6px 16px;
        color: white;
        text-align: center;
    }

    .footer span {
        font-size: 0.8rem;
    }

    .sidenav {
        height: 100vh;
        width: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        background: #8acb87 0% 0% no-repeat padding-box;
        overflow-x: hidden;
    }

    .nav-link {
        margin-bottom: 10px;
        border-left: 3px solid transparent;

        .nav-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 50px;
            margin-left: 30px;
        }

        &:hover {
            background-color: #ffffff19;
        }

        &.active {
            background-color: #ffffff19;
            border-left: 5px solid #f7cb7c;

            .submenu-active {
                background-color: #8acb87;
            }
        }
    }

    .sidenav .text {
        color: white;
        padding-left: 1rem;
        font-size: 15px;
    }

    .side-menu {
        padding-top: 1rem;
        margin-top: 0.5rem;
        min-height: calc(100vh - 210px);
    }

    .copy-right {
        margin-top: 10px;
    }
}