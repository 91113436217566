@import "./../../colors.scss";

.signup-wrapper {
  background-image: url("./../../assets/images/login-footer.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;

  .sign-up-box {
    width: 400px;
    margin: 0 auto;
    margin-top: 5%;
    box-shadow: 0px 20px 40px #00000014;
    padding: 30px;
    background-color: #fff;

    .round-img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }

    button {
      width: 100%;
      background-color: #8acb87;
      border: 0;
      padding: 10px 20px;
      font-size: 14px;
      color: #fff;
      margin: 6px 0 20px 0;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 4px;
    }
  }
}
