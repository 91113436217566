.wrapper-messaging {
  // .row-top {
  //     margin-bottom: 30px;

  //     .title {
  //         color: #4bbb46;
  //         font-size: 20px;
  //         font-weight: 600;
  //         width: 300px;
  //         display: inline-block;
  //         border-bottom: 1px solid #4bbb46;
  //     }
  // }

  .row-messenger {
    height: calc(100vh - 280px);
    padding-bottom: 10px;
    // border-bottom: 1px solid #c5c5c5;

    .col-md-5 {
      // border-right: 1px solid #c5c5c5;

      &.profile-list {
        max-height: calc(100vh - 200px);
        overflow: auto;
      }

      .wrapper-person {
        cursor: pointer;
        padding: 10px;
        border-radius: 5px;

        &.active {
          background-color: #c5e5c3;
        }

        .wrapper-name {
          display: inline-block;
          position: relative;
          // top: 8px;
          margin-left: 5px;

          .name {
            font-weight: 600;
            font-size: 15px;
            color: #000;
          }

          .message {
            font-size: 13px;
          }
        }

        .wrapper-time {
          display: inline-flex;
          float: right;
          position: relative;
          top: 16px;
          font-size: 14px;
        }
      }

      .online {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        background: #48c741;
        border-radius: 50%;
        position: relative;
        top: 32px;
        right: 13px;
      }

      .idle {
        display: inline-block;
        width: 15px;
        height: 15px;
        background: #ff9a28;
        border-radius: 50%;
        position: relative;
        top: 20px;
        right: 13px;
      }

      .offline {
        display: inline-block;
        width: 15px;
        height: 15px;
        background: none;
        border-radius: 50%;
        position: relative;
        top: 20px;
        right: 13px;
      }
    }
  }

  .col-messaging {
    .wrapper-messaging {
      height: 100%;
      background-color: #fbfbfc;
      padding: 0 15px;
      position: relative;

      .wrapper-person {
        padding: 10px;
        border-radius: 5px;
        border-bottom: 3px solid #f6f6f7;
        margin-bottom: 20px;

        .wrapper-name {
          display: inline-block;
          position: relative;
          top: 8px;
          margin-left: 5px;

          .name {
            font-weight: 600;
            font-size: 15px;
            color: #000;
          }

          .message {
            font-size: 13px;
          }
        }

        .wrapper-options {
          display: inline-flex;
          float: right;
          position: relative;
          top: 16px;
          font-size: 14px;
        }

        .active {
          background-color: #c5e5c3;
          padding: 10px;
          border-radius: 5px;
        }
      }

      .message-received {
        margin-bottom: 10px;

        .message {
          display: inline-block;
          max-width: 95%;
          font-size: 13px;
          padding: 10px;
          background-color: #8acb87;
          color: #fff;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          max-width: 20rem;
        }

        .wrapper-options {
          display: inline-flex;
          float: right;
          position: relative;
          top: 16px;
          font-size: 14px;
        }

        .wrapper-time {
          font-size: 10px;
          padding-left: 5px;
          padding-top: 0px;
          color: #acacb1;
        }
      }

      .message-sent {
        margin-bottom: 10px;
        // float: right;
        display: flex;
        clear: both;
        align-items: flex-end;
        flex-direction: column;

        .message {
          width: auto;
          float: right;
          font-size: 13px;
          padding: 10px;
          background-color: #ffbe46;
          color: #fff;
          border-top-left-radius: 15px;
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          max-width: 20rem;
        }

        .wrapper-options {
          display: inline-flex;
          float: right;
          position: relative;
          top: 16px;
          font-size: 14px;
        }

        .wrapper-time {
          clear: both;
          text-align: right;
          font-size: 10px;
          padding-left: 10px;
          padding-top: 0px;
          color: #acacb1;
          text-align: right;
          padding-right: 5px;
        }
      }

      .message-wrapper {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: calc(100vh - 370px);
        margin-bottom: 60px;
        padding: 15px;
      }

      .wrapper-type-message {
        position: absolute;
        bottom: 5px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        right: 15px;
        left: 5px;
        box-shadow: 0 0 10px 0px #e6e6e6;
        border-radius: 5px;
        overflow: hidden;

        input {
          border: 0;
          background-color: #fff;
          padding: 15px 20px;
          font-size: 14px;
          width: 100%;
        }

        .wrapper-icons {
          width: 200px;
          color: #b5c6d0;
          padding-top: 6px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 20px 0 0;

          .icon-attachment {
            transform: rotate(-45deg);
          }

          .icon-send {
            color: #4bbb46;
            transform: rotate(45deg);
          }
        }
      }

      .online {
        display: inline-block;
        width: 15px;
        height: 15px;
        background: #34d82a;
        border-radius: 50%;
        position: relative;
        top: 11px;
        right: 13px;
      }
    }

    .senderName{
      font-size: 13px;
    }
  }

  .wrapper-copy-right {
    margin-top: 20px;

    p {
      font-size: 10px;
    }
  }
}
