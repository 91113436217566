.section-1,
.section-2,
.section-3,
.section-4,
.section-5,
.section-6,
.section-7,
.section-8,
.section-9,
.section-10,
.section-11,
.section-12,
.section-13,
.section-14,
.section-15,
.section-16,
.section-17,
.section-18,
.section-19,
.section-20,
.section-21 {
  margin-top: 10px;
}

.section {
  margin: 0 40px 0 40px;

  .section-heading,
  .sub-heading {
    font-size: 25px;
    font-weight: 500;
    margin-top: 10px;
  }
}

.heading {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 55px 0 30px 0;
}

li {
  color: black;
  font-size: 15px;
  font-weight: 400;
}

li {
  list-style: none;
  margin-top: 20px;
}

.icon {
  margin: 30px 0 0 30px;
}

.company-icon {
  width: 150px;
}

.bullet {
  list-style: disc;
}

.circle {
  list-style: circle;
}

.numbers {
  list-style: decimal;
}
