@import "../../colors.scss";

.booking-wrap {
    .booking-box {
        padding: 10px;
        border-radius: 10px;
        margin: 10px;
        .title {
            font-weight: bold;
        }
    }

    .box-section {
        margin-top: 2rem;
    }
}

.facilities-items-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .box {
        width: 170px;
        height: 185px;
        background-position: center;
        background-size: cover;
        border-radius: 7px;
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        overflow: hidden;

        .background-black {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 1;
            background-color: #0000004f;
        }

        .inactive {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            background: #FEBE4D;
            color: white;
            padding: 3px 10px;
            font-size: 12px;
            z-index: 1;
            text-align: center;
            font-weight: 500;
            height: 30px;
            border-radius: 0;
            line-height: 23px;
        }
    }
}

.hidden-box {
    height: 150px;
    width: 150px;
    background-color: RGBA(0, 0, 0, 0);
    border-radius: 5px;
}

.box span {
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
}
