.payment-page {
  .wrapper-payments-table {
    .td-ref-no {
      font-weight: 600;
    }

    .td-action-by {
      img {
        width: 34px;
        border-radius: 50%;
      }

      p {
        display: inline-block;
        margin-left: 10px;
      }
    }

    .td-status {
      text-align: center;
      display: flex;

      .icon-done {
        font-size: 18;
        color: #4bbb46;
      }

      .icon-denied {
        color: #ff5440;
      }

      .icon-pending {
        color: #ffbe46;
      }
    }
  }

  .payment-info-drawer {
    .amount {
      font-size: 27px;
      font-weight: 500;
      text-align: center;
      margin-top: 10px;
      color: #8acb87;
    }

    .payee-lbl {
      font-size: 22px;
      font-weight: 500;
    }

    .payment-info {
      .label-heading {
        font-weight: 600;
        display: inline-block;
        color: #575761;
        font-size: 17px;
      }

      .label {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.7;
      }

      .value {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .close-button {
    color: #ffbe46;
  }

  .wrapper-bottom-set {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 9px;
    flex-direction: row;
    flex-wrap: wrap;

    .other-image-wrap {
      position: relative;
      border-radius: 8px;
      border-color: "black";

      .remove-button {
        position: absolute;
        background-color: transparent;
        color: white;
        top: 13px;
        right: 15px;
        border-radius: 20px;
        background: #d44242;
        width: 20px;
        height: 20px;
        border: none;
        font-size: 10px;
      }
    }

    .other-image-upload-icon {
      width: 75px;
      height: 70px;
      background: #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      border-radius: 8px;
      color: #8bcb89;
      font-size: 18px;
    }

    img {
      width: 75px;
      height: 70px;
      object-fit: cover;
      border-radius: 10px;
      margin-top: 10px;
    }
  }
}