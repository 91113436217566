.booking-wrap {
  .available-times {
    margin-bottom: 10px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
  }
  .facility-navbar-wrap {
    display: grid;
    grid-template-columns: 1fr 300px;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    align-content: center;

    .week-select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 25px;
      margin-top: 3rem;
    }
    @media (min-width: 1200px) and (max-width: 1680px) {
      .week-select {
        margin-bottom: 20px;
      }
    }

    .btn {
      background-color: #575761;
      width: 40px;
      height: 40px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.facility-booking-drawer {
  .body {
    padding: 25px;
    overflow: auto;
  }

  .reserved-profile-image {
    width: 50px;
    height: 50px;
  }

  .invite-user-img {
    width: 50px !important;
    height: 50px;
    object-fit: cover;
    border-radius: 59px;
  }

  .wrapper-reserved-by {
    margin-bottom: 30px;

    .title {
      font-weight: 600;
      margin-bottom: 15px;
    }

    .img-profile {
      width: 40px;
    }

    .wrapper-name {
      display: inline-block;
      position: relative;
      left: 10px;
      top: 5px;

      .title {
        font-size: 13px;
        margin: 0;
      }

      .sub-title {
        font-size: 13px;
        margin-top: 0px;
        color: #9a9a9a;
        font-weight: 500;
      }
    }
  }

  .wrapper-invitees {
    margin-bottom: 30px;

    .title {
      font-weight: 600;
      margin-bottom: 15px;
      display: inline-block;
    }

    .expand-all {
      float: right;
      font-weight: 600;
      color: #4bbb46;
    }

    .img-profile {
      width: 40px;
      border: 3px solid #4bbb46;
    }
  }

  .wrapper-booking-summary {
    margin-bottom: 30px;

    .title {
      font-weight: 600;
      margin-bottom: 15px;
      display: inline-block;
    }

    .wrapper-date-and-time {
      .wrapper-date {
        display: inline-block;

        .icon-date {
          color: #a0a0a6;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          display: inline-block;
          margin-left: 10px;
        }
      }
      .wrapper-time-header {
        p {
          font-size: 14px;
          font-weight: 600;
          display: inline-block;
          margin-bottom: 5px;
        }
      }

      .wrapper-time {
        display: inline-block;
        margin-bottom: 10px;

        .icon-clock {
          color: #a0a0a6;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          display: inline-block;
          margin-left: 10px;
        }
      }

      .wrapper-fees {
        margin-bottom: 10px;
        margin-top: 10px;

        .icon-lock {
          color: #a0a0a6;
        }

        p {
          font-size: 14px;
          font-weight: 600;
          display: inline-block;
          margin-left: 10px;
        }
      }

      .wrapper-charges {
        width: 100%;
        font-weight: 400;

        .wrapper-charge {
          .charge-type {
            width: 155px;
            font-weight: 400;
            margin-left: 25px;
            font-size: 13px;
          }

          .charge {
            font-weight: 400;
            font-size: 13px;
          }
        }
      }

      .wrapper-total {
        .charge-type {
          font-weight: 600 !important;
        }

        .charge {
          font-weight: 600 !important;
        }

        .icon-question {
          color: #ffbe46;
          font-size: 16px;
          margin-left: 10px;
        }
      }
    }
  }

  .wrapper-payment-summary {
    .payment {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.8rem;

      .payment-item {
        display: flex;
        flex-direction: row;

        .description {
          font-size: 13px;
          font-weight: 400;
          flex: 1;
          margin: 4px 0px 0px 25px;
        }

        .text {
          font-size: 13px;
          font-weight: 400;
          flex: 1;
          margin: 4px 0px 0px 0px;
        }
      }
    }
  }

  .btn-collect-payment {
    background-color: #4bbb45;
    color: #fff;
    border: 1px solid #4bbb45;
    font-size: 15px;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
  }

  .link-decline {
    margin: 15px 0;
    text-align: center;
    color: #f40105;
    font-weight: 600;
  }

  .link-decline:hover {
    cursor: pointer;
  }
}

.filter-area-booking {
  top: 8.5rem;
  right: 21.7rem;
}
.filter-wrapper.filter-area-booking .filter-item .label {
  min-width: 40px;
}
.filter-area-booking {
  top: 148px;
  right: 350px;
}
@media (min-width: 768px) and (max-width: 1680px) {
  .filter-area-booking {
    top: 135px;
    right: 340px;
  }
}
