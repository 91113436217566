.filter-wrapper {
    position: absolute;
    display: flex;
    background: #ebebeb;
    min-width: 14rem;
    border-radius: 0.7rem;
    padding: 10px;
    flex-direction: column;

    .filter-item {
        flex: 1;
        margin: 1rem;
        display: flex;
        align-items: baseline;
        margin: 10px;
        &:first-child{
            margin-top: 0;
        }

        .label {
            white-space: nowrap;
            min-width: 5rem;
            margin-right: 1.5rem;
            max-width: 5rem;
            font-size: 14px;
        }

        .type {
            min-width: 10rem;
            max-width: 10rem;
            .form-control{
                border-radius: 8px;
                background: #fff;
                border: 2px solid #ebebec;
                height: 40px;
                font-size: 14px;
            }
        }
    }
}