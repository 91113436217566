@import "../../colors.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.dashboard-screen {
  .dashboard-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem 3rem;
    margin-bottom: 60px;

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }

    .payment-status-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 25px;
      float: right;

      .yellow-bg {
        background-color: #ffbe46;
      }

      .green-bg {
        background-color: #4bbb46;
      }

      .red-bg {
        background-color: #ff5440;
      }
    }
  }

  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .value-card {
    padding: 1.3rem 1rem;
    color: white;
    border-radius: 0.2rem;
  }

  .value-card-heading {
    // font-size: 0.8rem;
    font-size: 1.063rem;
    font-weight: 700;
  }

  .value-card-amount {
    white-space: nowrap;
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.2;
  }



  .margin-top {
    margin-top: 3rem;
  }

  .margin-bottom {
    margin-bottom: 1.5rem;
  }

  .condo-section {
    margin-top: 1.25rem;

    .condo-icons {
      height: 3.8rem;
      width: 3.8rem;
      border-radius: 0.5rem;
      margin-right: 1rem;
    }
  
    .occupied {
      background-color: rgba(75, 187, 70, 0.1);
    }
  
    .vacant {
      background-color: rgba(255, 190, 70, 0.1);
    }
  
    .users {
      background-color: rgba(58, 122, 255, 0.1);
    }

    .condo-stats-heading {
      font-size: 1rem;
    }
  
    .condo-stats-amount {
      font-size: 2.7rem;
      line-height: 0.9;
      font-weight: 500;
    }
  }

  .helpers {
    width: 4.5rem;
    font-size: 0.6rem;
    text-align: center;
    padding: 0.3rem 0rem;
  }

  .helper-dot {
    padding: 0.3rem 0rem;
  }

  #staff-reporting tbody tr td:nth-child(2),
  #staff-reporting tr th:nth-child(2) {
    font-weight: bold;
  }

  #staff-reporting .profile-pic {
    width: 2rem;
    height: 2rem;
    border-radius: 5rem;
    margin-top: 0.6rem;
  }

  #staff-reporting td {
    padding: 1rem;
  }

  .box {
    height: 100px;
    width: 100px;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;

    .content {
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      span {
        color: white;
        font-size: 0.8rem;
        text-align: center;
      }
    }
  }

  .booking-counter {
    // font-size: 1.7rem !important;
    font-size: 2.5rem !important;
    line-height: 1.325;
  }

  .blur {
    background: #00000033;
    backdrop-filter: blur(3px);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .count {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin-left: 10px;
    background: #ff5440;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .timeline {
    flex: 0 0 100%;
    max-width: 100%;

    .timeline-single {
      display: flex;
    }
  }

  .msg {
    position: relative;
    width: 100%;
    margin: 1.5rem 0rem;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .user {
    width: 100%;
    display: flex;
    flex-direction: row;

    .user_details {

      margin-left: 10px;

      .user_name {
        font-size: 1rem;
        font-weight: bold;
      }

      .user_role {
        font-size: 0.7rem;
        font-weight: normal;
        color: #a1a1a8;
      }

      .user_payment_name,
      .user_reminder_name {
        font-size: 0.8rem;
      }

      .user_payment_time {
        color: #ff5440;
        ;
        font-weight: bold;
      }

      .user_reminder_time {
        color: #97d094;
        font-weight: bold;
      }
    }
  }

  .date {
    width: 50%;
    text-align: right;

    .date_content {
      display: flex;
      flex-direction: column;

      .amount {
        font-weight: bold;
      }
    }

    .icons_container {
      padding-top: 5px;

      .icon:hover {
        cursor: pointer;
      }
    }
  }

  .user_avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    max-width: 100%;
    vertical-align: middle;
    align-self: center;
  }

  .stats-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .aging-style {
    display: flex;
    align-items: center;
  }

  .view {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    color: #a1a1a8;
  }
}