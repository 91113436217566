@import "../../colors.scss";

.dashboard-filter-wrap {
  position: relative;

  .condo-category {
    width: auto;
    color: #40404d;
    background-color: #f7f7f9;
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
    border-radius: 0.2rem;
    cursor: pointer;
    font-weight: bold;
    white-space: nowrap;
  }

  .active {
    color: white;
    background-color: $primary-color;
  }

  .filter-padding {
    padding: 0px 0px 0 5px;
  }

  .custom-picker {
    //position: absolute;
    top: 30px;
    background: white;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 10px;
    display: none;
    padding: 0px 0px 0 5px;

    &.open {
      display: flex;
    }

    .filter-button {
      width: auto;
      //background-color: #3b7aff;
      padding: 5px 10px;
    }
  }
}
