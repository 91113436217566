.wrapper-sidenav {
    .wrapper-action-by {
        margin: 20px 0;

        .main-title {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 18px;
        }

        .img-action-by {
            width: 45px;
            border-radius: 50%;
        }

        .wrapper-name {
            display: inline-block;
            position: relative;
            top: 10px;
            margin-left: 20px;

            .title {
                font-weight: 600;
                font-size: 14px;
                color: #000;
            }

            .sub-title {
                font-weight: 300;
                font-size: 12px;
            }
        }
    }

    .wrapper-event-history {
        margin: 20px 0;

        .main-title {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 18px;
        }

        .wrapper-history {
            margin-bottom: 20px;

            p {
                font-size: 14px;
                font-weight: 600;

                span {
                    color: #56BF51;
                }
            }

        }

        .img-profile {
            width: 45px;
            border-radius: 50%;
        }

        .wrapper-name {
            display: inline-block;
            position: relative;
            top: 10px;
            margin-left: 20px;

            .title {
                font-weight: 600;
                font-size: 14px;
                color: #000;
            }

            .sub-title {
                font-weight: 300;
                font-size: 12px;
            }
        }

        .text {
            font-weight: 300;
            margin: 20px 0;
            margin-top: 10px;
        }
    }

    .wrapper-reply {
        margin: 20px 0;

        .main-title {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 18px;
        }

        input {
            border: 1px solid #56BF51;
            border-radius: 4px;
            padding: 10px 20px;
            width: 100%;
            height: 80px;
        }
    }

    .btn-send-message {
        width: 100%;
        padding: 15px 20px;
        border: 1px solid #56BF51;
        background-color: #56BF51;
        color: #fff;
        border-radius: 4px;
        margin-bottom: 20px;
    }

    .link-flag-event {
        text-align: center;
        font-weight: 600;
        color: #FF5440;
    }
}
.filter-wrapper.filter-area-compound .filter-item .label {
    min-width: 40px;
}
.filter-area-compound {
    top: 140px;
    right: 47px;
}
@media (min-width: 768px) and (max-width: 1680px) {
    .filter-area-compound {
        top: 127px;
        right: 40px;
    }
}