$primary-color: #4BBB46;
$primary-light-color: #9A67EA;
$primary-dark-color: #320B86;
$primary-text-color: #FFFFFF;
$background-color: #FAFAFA;
$cards-dialogs-color: #FFFFFF;
$error-color: #FF5440;
$warning-color: #FFBE46;

$dark-grey: #40404D;
$green: #8ACB87;
$light-grey: #575761;
$bg-grey: #F7F7F9;
$light-blue: #5A8DFF;
$bright-blue: #3A7AFF;
$aqua: #00C3B3;
$orange: #F4A21F;
$pink: #FF7666;
$purple : #B418EE
