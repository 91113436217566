@import "./../../colors.scss";

.visitorform-wrapper {
  background-image: url("./../../assets/images/background.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;

  .logo {
    background-image: url("../../assets/images/logo-liwe.png");
    height: 50px;
    width: 75px;
    background-repeat: round;
    position: fixed;
    top: 10px;
    left: 10px;
  }
  .visitorform-box {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 5%;
    box-shadow: 0px 20px 40px #00000014;
    padding: 30px;
    background-color: #fff;

    .title {
      font-weight: 600;
      font-size: 25px;
      color: #000;
      margin-bottom: 20px;
    }

    .sub-title {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 5px;
      color: #4e5983;
    }

    .check-box {
      display: flex;
      align-items: center;
    }
    input {
      width: 100%;
      background-color: #f1f2f4;
      border: 0;
      padding: 10px 20px;
      font-size: 13px;
      border-radius: 4px;
      margin-bottom: 15px;
    }

    input::placeholder {
      color: #0a1f44;
      opacity: 0.2;
    }

    input:focus,
    button:focus {
      outline: $primary-color !important;
    }

    button {
      width: 100%;
      background-color: #8acb87;
      border: 0;
      padding: 10px 20px;
      font-size: 14px;
      color: #fff;
      margin: 6px 0 20px 0;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 4px;
    }

    .clear-form {
      text-decoration: none;
      color: #8acb87;
      font-weight: 500;
      font-family: "Inter", sans-serif !important;
      line-height: 1.5;
      font-size: 1rem;
      cursor: pointer; 
      text-align: center;
    }

    clear-form:hover {
      color: #40404d;
    }

  }
}
