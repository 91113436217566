@import "./colors.scss";

.admin-wrap {
  &.dashboard-admin-layout {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .sidebar-wrapper-admin {
    .logo {
      padding: 6px 8px 6px 16px;
      font-size: 2rem;

      img {
        width: 100px;
        margin: 10px 0;
      }
    }

    .badge {
      float: right;
      margin-top: 10px;
      margin-right: 10px;
      background: black;
    }

    .logo p {
      color: white;
    }

    .sidebar-image {
      background-size: cover;

      position: relative;
      border-radius: 0.2rem;
      box-shadow: 0px 16px 40px #c6d1da29 padding-box;
      padding: 6px 8px 6px 16px;
      height: 8rem;
      width: 216px;
      margin: 0 auto;
      margin-bottom: 25px;
      overflow: hidden;

      .gradient {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(
          90deg,
          #ffffff00 25%,
          #ffffffa6 55%,
          #ffffff 100%
        );
      }
    }

    .condo-name {
      font-size: 0.8rem;
      font-weight: bold;
    }

    .numbers {
      font-size: 0.7rem;
      font-weight: 500;
    }

    .last-accessed {
      font-size: 0.6rem;
    }

    .last-accessed span {
      font-weight: 500;
    }

    .footer {
      padding: 0px 8px 6px 16px;
      color: white;
      text-align: center;
    }

    .footer span {
      font-size: 0.8rem;
    }

    .sidenav {
      height: 100vh;
      width: 250px;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      background: #8acb87 0% 0% no-repeat padding-box;
      overflow-x: hidden;

      .logout {
        position: absolute;
        bottom: 35px;
        background: #74ab72;
        width: 100%;
      }
    }

    .nav-link {
      margin-bottom: 10px;
      border-left: 3px solid transparent;

      .nav-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 50px;
        margin-left: 30px;
      }

      &:hover {
        background-color: #ffffff42;
      }

      &.active {
        background-color: #ffffff42;
        border-left: 3px solid #f7cb7c;
      }
    }

    .sidenav .text {
      color: white;
      padding-left: 1rem;
      font-size: 15px;
    }

    .side-menu {
      padding-top: 1rem;
      margin-top: 0.5rem;
      min-height: calc(100vh - 300px);
    }
  }

  .select-condo {
    width: 100%;
    padding: 15px;
    background: #e6e6e6;
    position: relative;
    select {
      font-weight: bold;
      max-width: 800px;
    }
  }



  .condo-section {
    margin-top: 1.25rem;

    .condo-icons {
      height: 3.8rem;
      width: 3.8rem;
      border-radius: 0.5rem;
      margin-right: 1rem;
    }
  
    .occupied {
      background-color: rgba(75, 187, 70, 0.1);
    }
  
    .vacant {
      background-color: rgba(255, 190, 70, 0.1);
    }
  
    .users {
      background-color: rgba(58, 122, 255, 0.1);
    }

    .condo-stats-heading {
      font-size: 1rem;
    }
  
    .condo-stats-amount {
      font-size: 2.7rem;
      line-height: 0.9;
      font-weight: 500;
    }
  }
}
